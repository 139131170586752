import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

// Internals
import "./LayoutDefault.css";
import { isLogged } from "utils/auth";

export const LayoutDefault = ({ children, ...rest }) => {
  const navigate = useNavigate();

  return (
    <div className="default-layout" {...rest}>
      <header className="d-flex flex-row justify-content-between bg-white mb-4">
        <Link to="/">
          <img
            src="/citrg-logo.png"
            alt="Logo do conselho"
          />
        </Link>

        {!isLogged() && (
          <Button variant="primary" onClick={() => navigate("/login")}>
            Fazer login
          </Button>
        )}

        {isLogged() && (
          <div>
            <Button variant="primary" onClick={() => navigate("/minha-conta")}>
              Editar Perfil
            </Button>

            <Button
              className="ms-2"
              variant="danger"
              onClick={() => navigate("/logout")}
            >
              Sair
            </Button>
          </div>
        )}
      </header>

      {children}

      <footer className="footer text-center mt-4">
        <img
            src="/citrg-logo.png"
            alt="Logo do conselho"
            className="my-3"
          />

        <p>
          Copyright © {new Date().getFullYear()} Conselho internacional de
          Terapia de Reprocessamento Generativo
        </p>

        <p>Todos os direitos reservados. CNPJ: 43.297.877/0001-07</p>
      </footer>
    </div>
  );
};

export default LayoutDefault;
