import * as React from "react";
import { useParams, Navigate } from "react-router-dom";
import "./PageProfile.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Linkify from "react-linkify";

import LayoutDefault from "layouts/LayoutDefault";
import { useGenericAPI } from "utils/hooks/useGenericAPI";
import { Loading } from "components/Loading";
import { ProfileCard } from "components/ProfileCard";

const convertToParagraph = data =>
  data.split("\n").map((paragraph, index) => <p key={index}>{paragraph}</p>);

export const PageProfile = () => {
  const { id } = useParams();
  const { getRequest, loading, data, error } = useGenericAPI(`user_profiles/`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getRequest(id), []);

  if (error) {
    return <Navigate to="/nao-encontrado" />;
  }

  if (loading || !data) {
    return <Loading text="Procurando perfil..." />;
  }

  const { membership, user_profile } = data;

  return (
    <LayoutDefault>
      <main>
        <Container>
          <Row>
            <h1>Perfil do Terapeuta</h1>
          </Row>
          <Row>
            <Col md={3}>
              <ProfileCard data={data} id={id} />
            </Col>

            <Col md={6}>
              <Card className="shadow border-light">
                <Card.Body>
                  <h2>Sobre {membership?.user?.name}</h2>
                  <Linkify>{convertToParagraph(user_profile?.about)}</Linkify>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3}>
              <Card className="shadow mb-3 border-light">
                <Card.Body>
                  <h2>Contatos</h2>
                  <Linkify>
                    {convertToParagraph(user_profile?.contacts)}
                  </Linkify>
                </Card.Body>
              </Card>

              <Card className="shadow border-light">
                <Card.Body>
                  <h2>Disponibilidade</h2>
                  <Linkify>
                    {convertToParagraph(user_profile?.availability)}
                  </Linkify>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </LayoutDefault>
  );
};

export default PageProfile;
