import { withFormik } from "formik";
import { withRouter } from "components/withRouter";
import { api } from "utils/api";
import { ProfileEditFields } from "components/ProfileEdit/ProfileEditFields";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";

export const ProfileEditForm = withRouter(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ data: { user_profile } }) => ({
      about: user_profile?.about,
      availability: user_profile?.availability,
      contacts: user_profile?.contacts,
      global_error: "",
      global_success: "",
    }),

    // Custom sync validation
    validate: values => {
      const errors = {};

      if (!values.about) {
        errors.about = "Preencha seu perfil.";
      }

      if (!values.availability) {
        errors.availability = "Preencha sua disponibilidade.";
      }

      if (!values.contacts) {
        errors.contacts =
          "Preencha como as pessoas podem entrar em contato com você.";
      }

      return errors;
    },

    handleSubmit: async (
      values,
      { setSubmitting, setFieldError, setFieldValue, props: { history } },
    ) => {
      setSubmitting(true);

      try {
        const request = await api.put("/me/1", values);

        if (request?.data) {
          setSubmitting(false);

          setFieldValue(
            "global_success",
            'Perfil atualizado com sucesso. Clique no botão "Ver perfil público" acima para visualizar.',
          );
        }
      } catch (error) {
        setSubmitting(false);

        if (error.response) {
          if (error.response.status === 422) {
            const data = error.response.data.errors;

            if (data) {
              Object.keys(data).map(errorKey =>
                setFieldError(errorKey, data[errorKey].join(", ")),
              );
            }

            if (error.response.error) {
              setFieldError("global_error", error.response.error);
            }
          }
        } else {
          console.log(error);
          setFieldError("global_error", defaultErrorMessage);
          global.Rollbar.warning("Global Error", error.toJSON());
        }
      }
    },

    displayName: "ProfileEditForm",
  })(ProfileEditFields),
);

export default ProfileEditForm;
