import * as React from "react";
import { Navigate } from "react-router-dom";

import "./PageProfile.css";
import ProfileDocumentationEditForm from "components/ProfileEdit/ProfileDocumentationEditForm";
import { useGenericAPI } from "utils/hooks/useGenericAPI";
import Loading from "components/Loading";
import { ProfileDocumentationWithMessage } from "components/ProfileEdit/ProfileDocumentationWithMessage";
import { user } from "utils/auth";

export const PageProfileDocumentationEdit = () => {
  const { getRequest, loading, data, error } = useGenericAPI(`me/`);
  const userData = user();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getRequest(""), []);

  if (error) {
    return <Navigate to="/nao-encontrado" />;
  }

  if (loading || !data) {
    return <Loading text="Carregando envio de documentação..." />;
  }

  const documentationStatus = data?.user_profile?.documentation_status;

  if (documentationStatus === "ok") {
    return (
      <ProfileDocumentationWithMessage
        message="Sua documentação está em dia, você não possui nenhum arquivo a ser enviado no momento. Caso esta informação esteja incorreta, fale conosco."
        status="ok"
        data={data}
      />
    );
  }

  if (documentationStatus === "analysis") {
    return (
      <ProfileDocumentationWithMessage
        message="Sua documentação está em análise pelo conselho, você não possui nenhum arquivo a ser enviado no momento. Assim que sua filiação for aceita, você receberá um aviso."
        status="analysis"
        data={data}
      />
    );
  }

  if (documentationStatus === "not_verified" || !userData.isCertified) {
    return (
      <ProfileDocumentationWithMessage
        message="De acordo com a verificação automática no IBFT, você possui pendências com o certificado de conclusão do curso. Entre em contato conosco o mais breve para regular a situação."
        status="not_verified"
        data={data}
      />
    );
  }

  return <ProfileDocumentationEditForm data={data} refreshPage={getRequest} />;
};

export default PageProfileDocumentationEdit;
