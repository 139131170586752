import * as React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Internals
import LayoutDefault from "layouts/LayoutDefault";
import { LoginForm } from "components/Auth/LoginForm";

export const PageLogin = () => {
  return (
    <LayoutDefault>
      <main>
        <Container className="my-auto">
          <Row className="justify-content-center align-items-center align-center">
            <Col lg={8}>
              <Card>
                <Card.Body>
                  <Card.Title as="h5">Faça seu login</Card.Title>
                  <p>
                    Utilize o mesmo login e senha da plataforma IBFT na qual
                    você assistiu seu curso.
                  </p>

                  <LoginForm />

                  <a
                    href="https://apolo.ibft.app/forgotten-password"
                    target="_blank"
                    rel="noreferrer"
                    className="mt-3"
                  >
                    Esqueci minha senha
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </LayoutDefault>
  );
};

export default PageLogin;
