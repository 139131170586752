import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import PageCard from "pages/PageCard";
import PageProfile from "pages/PageProfile";
import PageProfileEdit from "pages/PageProfileEdit";
import PageLogin from "pages/PageLogin";
import PageLogout from "pages/PageLogout";
import PageHome from "pages/PageHome";
import PageProfileDocumentationEdit from "pages/PageProfileDocumentationEdit";
import PrivateRoute from "components/Auth/PrivateRoute";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PageHome />} exact />
      <Route path="/login" element={<PageLogin />} exact />
      <Route path="/logout" element={<PageLogout />} exact />
      <Route path="/perfil/:id" element={<PageProfile />} />
      <Route
        path="/minha-conta"
        element={<PrivateRoute component={PageProfileEdit} />}
      />
      <Route
        path="/minha-conta/documentacao"
        element={<PrivateRoute component={PageProfileDocumentationEdit} />}
      />
      <Route path="/:id" element={<PageCard />} />
    </Routes>
  );
}

export default App;
