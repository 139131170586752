import * as React from "react";
import Loading from "components/Loading";

export const PageHome = () => {
  React.useEffect(() => {
    window.location.href = "https://site.cbtrg.com.br/";
  }, []);

  return <Loading text="Carregando site..." />;
};

export default PageHome;
