import * as React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { user } from "utils/auth";

export const ProfileDocumentationVerification = () => {
  const userData = user();

  return (
    <Card className="shadow border-light mb-4">
      <Card.Body>
        <h3 className="mb-3">Verificações automáticas</h3>

        <ListGroup className="mb-3">
          <ListGroup.Item>
            Certificado conclusão{" "}
            <Badge pill bg={userData.isCertified ? "success" : "danger"}>
              {userData.isCertified ? "VÁLIDO" : "INVÁLIDO"}
            </Badge>
          </ListGroup.Item>
        </ListGroup>

        <p>
          <small>
            Através do seu CPF/Documento informado, consultamos o instituto
            IBFT. Você precisa ter um certificado VÁLIDO no curso TRG.
          </small>
        </p>
      </Card.Body>
    </Card>
  );
};

export default ProfileDocumentationVerification;
