import { withFormik } from "formik";
import { LoginFormFields } from "./LoginFormFields";
import { withRouter } from "components/withRouter";
import { api } from "utils/api";
import { setLogin } from "utils/auth";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";

export const LoginForm = withRouter(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      email: "",
      password: "",
      global_error: "",
    }),

    // Custom sync validation
    validate: values => {
      const errors = {};

      if (!values.email) {
        errors.email = "Preencha seu e-mail.";
      }

      if (!values.password) {
        errors.password = "Preencha sua senha.";
      }

      return errors;
    },

    handleSubmit: async (
      values,
      { setSubmitting, setFieldError, props: { history } },
    ) => {
      setSubmitting(true);

      try {
        const request = await api.post("/me", values);

        if (request.data?.data) {
          setSubmitting(true);

          const { name, phone, email, isCertified } = request.data.data;

          setLogin({
            ...request.data.auth_headers,
            name,
            phone,
            email,
            isCertified,
          });

          history("/minha-conta");
        }
      } catch (error) {
        setSubmitting(false);

        if (error.response) {
          if (error.response.status === 422) {
            const data = error.response.data.errors;

            if (data) {
              Object.keys(data).map(errorKey =>
                setFieldError(errorKey, data[errorKey].join(", ")),
              );
            }

            if (error.response.error) {
              setFieldError("global_error", error.response.error);
            }
          } else if (error.response.status === 401) {
            setFieldError(
              "global_error",
              "E-mail ou senha inválidos, verifique o que foi digitado.",
            );
            global.Rollbar.warning("Global Error", error.toJSON());
          }
        } else {
          console.log(error);
          setFieldError("global_error", defaultErrorMessage);
          global.Rollbar.warning("Global Error", error.toJSON());
        }
      }
    },

    displayName: "LoginForm",
  })(LoginFormFields),
);
