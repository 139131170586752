import { withFormik } from "formik";
import { withRouter } from "components/withRouter";
import { api } from "utils/api";
import { ProfileDocumentationEditFields } from "components/ProfileEdit/ProfileDocumentationEditFields";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";

// Control activeStorage message keys by removing some fields
const EXISTING_FIELD_KEY = "__CBTRG__existing__";

export const PageProfileDocumentationEditForm = withRouter(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ data: { user_profile } }) => ({
      full_name: user_profile.full_name,
      phone: user_profile.phone,
      address: user_profile.address,
      address_zipcode: user_profile.address_zipcode,
      address_number: user_profile.address_number,
      address_complement: user_profile.address_complement,
      address_city: user_profile.address_city,
      address_neighborhood: user_profile.address_neighborhood,
      address_state: user_profile.address_state,
      address_country: user_profile.address_country || "br",
      id_file: user_profile?.id_file ? EXISTING_FIELD_KEY : undefined,
      membership_picture_file: user_profile?.membership_picture_file
        ? EXISTING_FIELD_KEY
        : undefined,
      address_confirmation_file: user_profile?.address_confirmation_file
        ? EXISTING_FIELD_KEY
        : undefined,
      global_error: "",
      global_success: "",
    }),

    // Custom sync validation
    validate: values => {
      const errors = {};

      const basicRequiredFields = [
        "address",
        "address_zipcode",
        "address_number",
        "address_city",
        "address_country",
      ];

      basicRequiredFields.map(field => {
        if (!values[field]) {
          errors[field] = "Campo obrigatório.";
        }

        return field;
      });

      if (!values.full_name) {
        errors.full_name = "Preencha seu nome completo.";
      }

      if (!values.phone) {
        errors.phone = "Preencha seu telefone pessoal.";
      }

      return errors;
    },

    handleSubmit: async (
      values,
      { setSubmitting, setFieldError, setFieldValue, props: { refreshPage } },
    ) => {
      setSubmitting(true);

      try {
        const formData = new FormData();

        // Remove __CBTRG__existing__  and undefined values
        // If we send the original values of the attachement fiels, Rails will complain with a Message Unverified field, because it's trying to upload a string
        Object.keys(values).filter(key =>
          values[key] !== undefined && values[key] !== EXISTING_FIELD_KEY
            ? formData.append(key, values[key])
            : false,
        );

        const request = await api.put("/me/1", formData);

        if (request?.data) {
          setSubmitting(false);

          setFieldValue(
            "global_success",
            "Documentação atualizada com sucesso. ",
          );

          refreshPage("");
        }
      } catch (error) {
        setSubmitting(false);

        if (error.response) {
          if (error.response.status === 422) {
            const data = error.response.data.errors;

            if (data) {
              Object.keys(data).map(errorKey =>
                setFieldError(errorKey, data[errorKey].join(", ")),
              );
            }

            if (error.response.error) {
              setFieldError("global_error", error.response.error);
            }
          }
        } else {
          console.log(error);
          setFieldError("global_error", defaultErrorMessage);
          global.Rollbar.warning("Global Error", error.toJSON());
        }
      }
    },

    displayName: "PageProfileDocumentationEditForm",
  })(ProfileDocumentationEditFields),
);

export default PageProfileDocumentationEditForm;
