import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { logout } from "utils/auth";

export const PageLogout = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    logout();
    setLoading(false);
  }, [setLoading]);

  if (loading) {
    return null;
  }

  return <Navigate to="/login" />;
};

export default PageLogout;
