import * as React from "react";
import Spinner from "react-bootstrap/Spinner";

export const Loading = ({ text = "" }) => (
  <main className="d-flex align-items-center flex-column align-content-center h-100">
    <Spinner animation="border" role="status" size="lg">
      <span className="visually-hidden">Carrregando...</span>
    </Spinner>

    {text && <p className="mt-3">{text}</p>}
  </main>
);

export default Loading;
