import * as React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { padMembership } from "utils/membership";

export const ProfileCard = ({ data }) => {
  if (!data) {
    return "Nenhuma filiação foi retornada no momento.";
  }

  const filliationType = data?.membership?.membership_group?.name.toUpperCase();
  const registerNumber = padMembership(data?.membership.register_number);

  return (
    <Card className="shadow border-light">
      <div className="profile__avatar">
        <Card.Img
          variant="top"
          src={data?.membership?.card_picture || data?.membership?.photo_url}
        />
      </div>
      <Card.Body className="text-center">
        <Card.Title>{data?.membership?.user?.name}</Card.Title>
        <Card.Text>
          CITRG{" "}
          <Badge bg={filliationType === "BLACK" ? "black" : "blue"}>
            {filliationType}
          </Badge>
          <br />
          Registro: {registerNumber}
          <br />
          <Link
            to={`/${registerNumber}`}
            title="Visualizar carteira do CITRG"
            className="mt-3 d-block"
          >
            Ver carteira on-line
          </Link>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProfileCard;
