export const parseNumber = membershipNumber =>
  (membershipNumber || "").replace(".", "");

export const getByNumber = (number = null, data = []) =>
  data.find(
    membership =>
      !!membership["CARTEIRA"] &&
      parseNumber(membership["CARTEIRA"]) === parseNumber(number),
  );

export const padMembership = (number = null) =>
  number.toString().padStart(5, "0");
