import * as React from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { FaExpeditedssl } from "react-icons/fa";

import LayoutDefault from "layouts/LayoutDefault";
import { FormField } from "components/Form/FormField";
import { ProfileCard } from "components/ProfileCard";
import { padMembership } from "utils/membership";

export const ProfileEditFields = ({
  errors,
  values,
  touched,
  isSubmitting,
  handleBlur,
  handleChange,
  handleSubmit,
  data,
}) => {
  const navigate = useNavigate();

  const alertErrors = errors.global_error;
  const alertSuccess = values.global_success;

  return (
    <LayoutDefault className="default-layout">
      <main>
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col className="d-flex justify-content-between align-items-center flex-row my-4">
                <div>
                  <h1>Perfil do Terapeuta — Editar</h1>
                </div>
                <Button
                  disabled={isSubmitting}
                  size="md"
                  type="submit"
                  onClick={() =>
                    navigate(
                      `/perfil/${padMembership(
                        data?.membership?.register_number || 0,
                      )}`,
                    )
                  }
                >
                  Ver perfil público
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  Edite seu perfil público nesta página. Pedimos que você tenha
                  atenção as informações compartilhadas, visto que após salvo,
                  seus dados desta página serão públicos. Nenhuma outra
                  informação será compartilhada, além das que você consegue
                  editar.
                </p>
              </Col>
            </Row>
            <Row>
              {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}
            </Row>
            <Row>
              <Col md={3}>
                <ProfileCard data={data} />

                {data.membership && (
                  <p className="text-center py-3">
                    <small>
                      Sua foto poderá ser modificada na renovação de sua
                      filiação.
                    </small>
                  </p>
                )}
              </Col>

              <Col md={6}>
                <Card className="shadow border-light">
                  <Card.Body>
                    <h2 className="mb-2">Sobre {data?.me?.name}</h2>

                    <FormField
                      type="textarea"
                      fieldAttributes={{
                        as: "textarea",
                        style: { minHeight: 712 },
                      }}
                      name="about"
                      error={errors.about}
                      touched={touched.about}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.about}
                      placeholder="Fale sobre você, sua carreira e sua jornada como terapeuta."
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col md={3}>
                <Card className="shadow mb-3 border-light">
                  <Card.Body>
                    <h2 className="mb-2">Contatos</h2>

                    <FormField
                      type="textarea"
                      fieldAttributes={{
                        as: "textarea",
                        style: { minHeight: 200 },
                      }}
                      name="contacts"
                      error={errors.contacts}
                      touched={touched.contacts}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.contacts}
                    />

                    <p>
                      Exemplo:
                      <br />
                      - Telefone: +55 81 98989898
                      <br />
                      - E-mail: fulano@sicrano.com
                      <br />
                      - Instagram: @sicranao
                      <br />- Facebook: @sicranao
                    </p>
                  </Card.Body>
                </Card>

                <Card className="shadow border-light">
                  <Card.Body>
                    <h2 className="mb-2">Disponibilidade</h2>

                    <p>Digite abaixo sua disponibilidade de atendimento.</p>

                    <FormField
                      type="textarea"
                      fieldAttributes={{
                        as: "textarea",
                        style: { minHeight: 200 },
                      }}
                      name="availability"
                      error={errors.availability}
                      touched={touched.availability}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.availability}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex align-content-center flex-column">
                {alertSuccess && (
                  <Alert variant="success">{alertSuccess}</Alert>
                )}

                <Button
                  disabled={isSubmitting}
                  variant="primary"
                  size="lg"
                  type="submit"
                  className="mt-3"
                >
                  {isSubmitting && (
                    <div>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Processando...</span>
                      </Spinner>
                    </div>
                  )}

                  {!isSubmitting && (
                    <div>
                      <FaExpeditedssl /> Salvar preferências
                    </div>
                  )}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </main>
    </LayoutDefault>
  );
};

export default ProfileEditFields;
