import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { FaExpeditedssl } from "react-icons/fa";

import LayoutDefault from "layouts/LayoutDefault";
import { FormField } from "components/Form/FormField";
import { AddressForm } from "components/ProfileEdit/AddressForm";
import { ProfileDocumentationVerification } from "components/ProfileEdit/ProfileDocumentationVerification";
import { ProfileDocumentationHeader } from "components/ProfileEdit/ProfileDocumentationHeader";
import { ProfileDocumentationAttachmentFields } from "components/ProfileEdit/ProfileDocumentationAttachmentFields";

export const ProfileDocumentationEditFields = ({
  errors,
  values,
  touched,
  isSubmitting,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldError,
  setFieldValue,
  setValues,
  data,
}) => {
  const alertErrors = errors.global_error;
  const alertSuccess = values.global_success;

  return (
    <LayoutDefault className="default-layout">
      <main>
        <Form onSubmit={handleSubmit}>
          <Container>
            <ProfileDocumentationHeader />
            <Row>
              <Col>
                <p>
                  Sua documentação precisa de atualização. Esta etapa acontecerá
                  sempre durante o processo de filiação ou renovação de sua
                  filiação. Para prosseguir, você precisará enviar todos os
                  documentos. Após o envio, nossa equipe fará a aprovação ou
                  recusa de algum documento. Você ficará sabendo nesta própria
                  página se algum estiver faltando.
                </p>
              </Col>
            </Row>
            <Row>
              {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}
            </Row>
            <Row>
              <Col>
                <ProfileDocumentationVerification />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="shadow border-light">
                  <Card.Body>
                    <h3 className="mb-2">Dados Pessoais</h3>
                    <p>Preenche seus dados abaixo:</p>

                    <p>
                      CPF/Documento informado: {data.me.doc_number} (não pode
                      ser modificado)
                      <br />
                      E-mail: {data.me.email} (não pode ser modificado —
                      Consulte suporte)
                    </p>

                    <FormField
                      name="full_name"
                      error={errors.full_name}
                      touched={touched.full_name}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.full_name}
                      placeholder="Nome completo"
                      label="Nome completo"
                    />

                    <FormField
                      name="phone"
                      error={errors.phone}
                      touched={touched.phone}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values.phone}
                      placeholder="Telefone"
                      label="Telefone"
                    />

                    <AddressForm
                      errors={errors}
                      values={values}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      data={data}
                      setValues={setValues}
                    />
                  </Card.Body>
                </Card>

                <hr />

                <ProfileDocumentationAttachmentFields
                  setFieldValue={setFieldValue}
                  data={data}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="d-flex align-content-center flex-column">
                {alertSuccess && (
                  <Alert variant="success">{alertSuccess}</Alert>
                )}

                <Button
                  disabled={isSubmitting}
                  variant="primary"
                  size="lg"
                  type="submit"
                  className="mt-3"
                >
                  {isSubmitting && (
                    <div>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Processando...</span>
                      </Spinner>
                    </div>
                  )}

                  {!isSubmitting && (
                    <div>
                      <FaExpeditedssl /> Salvar e enviar documentação
                    </div>
                  )}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </main>
    </LayoutDefault>
  );
};

export default ProfileDocumentationEditFields;
