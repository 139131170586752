import * as React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

export const ProfileDocumentationAttachmentFields = ({
  setFieldValue,
  data,
}) => {
  const { user_profile } = data;
  const handleFileChangeFor = fieldName => event =>
    setFieldValue(fieldName, event.currentTarget.files[0]);

  return (
    <Card className="shadow border-light">
      <Card.Body>
        <h3 className="mb-3">Anexos</h3>
        <ListGroup className="mb-3">
          <ListGroup.Item>
            <Form.Group controlId="address_confirmation_file" className="mb-3">
              <Form.Label>Comprovante de residência</Form.Label>
              {user_profile.address_confirmation_file && (
                <a
                  href={user_profile.address_confirmation_file}
                  title="Clique para abrir o arquivo enviado"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block"
                >
                  Ver arquivo enviado
                </a>
              )}

              {!user_profile.address_confirmation_file && (
                <Form.Control
                  type="file"
                  size="sm"
                  name="address_confirmation_file"
                  onChange={handleFileChangeFor("address_confirmation_file")}
                />
              )}
            </Form.Group>
          </ListGroup.Item>

          <ListGroup.Item>
            <Form.Group controlId="id_file" className="mb-3">
              <Form.Label>Comprovante de identidade (Imagem ou PDF)</Form.Label>
              {user_profile.id_file && (
                <a
                  href={user_profile.id_file}
                  title="Clique para abrir o arquivo enviado"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block"
                >
                  Ver arquivo enviado
                </a>
              )}

              {!user_profile.id_file && (
                <Form.Control
                  type="file"
                  size="sm"
                  name="id_file"
                  onChange={handleFileChangeFor("id_file")}
                />
              )}
            </Form.Group>
            <small className="text-warning">
              Documento com foto que contendo RG e CPF
            </small>
          </ListGroup.Item>

          <ListGroup.Item>
            <Form.Group controlId="membership_picture_file" className="mb-3">
              <Form.Label>Foto para carteira e perfil</Form.Label>
              {user_profile.membership_picture_file && (
                <a
                  href={user_profile.membership_picture_file}
                  title="Clique para abrir o arquivo enviado"
                  target="_blank"
                  rel="noreferrer"
                  className="d-block"
                >
                  Ver arquivo enviado
                </a>
              )}

              {!user_profile.membership_picture_file && (
                <Form.Control
                  type="file"
                  size="sm"
                  name="membership_picture_file"
                  onChange={handleFileChangeFor("membership_picture_file")}
                />
              )}
            </Form.Group>

            <small className="text-warning">
              Foto no formato 3x4 e com um fundo branco.
            </small>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default ProfileDocumentationAttachmentFields;
