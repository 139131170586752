import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import { FormField } from "components/Form/FormField";

export const LoginFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
}) => {
  const alertErrors = errors.global_error || errors.checkout_id;

  return (
    <Form onSubmit={handleSubmit}>
      <FormField
        type="email"
        placeholder="Digite seu e-mail"
        name="email"
        error={errors.email}
        touched={touched.email}
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={values.email}
      />

      <FormField
        type="password"
        placeholder="Digite sua senha"
        name="password"
        error={errors.password}
        touched={touched.password}
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={values.password}
      />

      {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}

      <div className="d-grid gap-2">
        <Button
          disabled={isSubmitting}
          variant="primary"
          type="submit"
          className="mt-3"
        >
          {isSubmitting && (
            <div>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Processando...</span>
              </Spinner>
            </div>
          )}

          {!isSubmitting && <div>Entrar</div>}
        </Button>
      </div>
    </Form>
  );
};

LoginFormFields.propTypes = {};
