import React from "react";

export const Card = ({ register, member }) => (
  <div>
    <div className="CardWrapper">
      <div className={`Card Card--${member["CATEGORIA"]}`}>
        <div className="Card__Header">
          <img src={`/${member["CATEGORIA"]}-logo.png`} alt="CITRG LOGO" />
        </div>

        <div className="Card__Body">
          <div className="Card__Image">
            <img
              alt={`Imagem de ${member["NOME"]}`}
              src={member["PHOTO"]}
              className="img-fluid"
            />
          </div>

          <div className="Card__Info">
            <span className="Card__InfoName">
              <h3>Nome Completo</h3>
              <p>{member["NOME"]}</p>
            </span>
            <span className="Card__InfoCPF">
              <h3>CPF</h3>
              <p>***.***.***-**</p>
            </span>
            <span className="Card__Valid">
              <h3>Validade &nbsp;&nbsp;&nbsp; Registro</h3>
              <p>
                {member["VALIDADE"]} &nbsp;&nbsp;&nbsp; {member["CARTEIRA"]}
              </p>
            </span>
          </div>
        </div>

        <div className="Card__Footer">
          <p>
            Terapeuta <strong>Certificado</strong>
          </p>
          <p>
            <strong>{member["CATEGORIA"]}</strong> member
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Card;
