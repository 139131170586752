import * as React from "react";
import { Navigate } from "react-router-dom";

import "./PageProfile.css";
import ProfileEditForm from "components/ProfileEdit/ProfileEditForm";
import { useGenericAPI } from "utils/hooks/useGenericAPI";
import Loading from "components/Loading";

export const PageProfileEdit = () => {
  const { getRequest, loading, data, error } = useGenericAPI(`me/`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getRequest(""), []);

  if (error) {
    return <Navigate to="/nao-encontrado" />;
  }

  if (loading || !data) {
    return <Loading text="Carregando seu perfil..." />;
  }

  if (data?.user_profile?.documentation_status !== "ok") {
    return <Navigate to="/minha-conta/documentacao" />;
  }

  return <ProfileEditForm data={data} />;
};

export default PageProfileEdit;
