import axios from "axios";

import { setLogin, user } from "utils/auth";

const instance = axios.create({
  // baseURL: "http://localhost:3004/api/v1/",
  baseURL: "https://api.cbtrg.com.br/api/v1/",
  timeout: 70000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  config => {
    const userData = user();

    if (userData) {
      if (userData["access-token"]) {
        config.headers["access-token"] = userData["access-token"];
      }
      if (userData.client) {
        config.headers["client"] = userData.client;
      }
      if (userData.uid) {
        config.headers["uid"] = userData.uid;
      }
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  },
);

export const api = instance;

export const refreshUserAuth = async instance => {
  const request = await instance.get("/auth/validate_token");

  const { "access-token": token, client, uid } = request.headers;
  const { name, phone, email, isCertified } = request.data.data;

  setLogin({
    token,
    client,
    uid,
    name,
    phone,
    email,
    isCertified,
  });
};

const CEPS = [];

const errorReturn = {
  data: {
    error: true,
    address: "",
  },
};

export const callCep = cep => {
  return new Promise(async (res, rej) => {
    if (CEPS[cep]) {
      return res(CEPS[cep]);
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      console.log(response);

      if (response) {
        CEPS[cep] = response;
      }

      // erro is the name of viaCEP object, not error
      if (response.data?.erro === true) {
        CEPS[cep] = errorReturn;

        return res(errorReturn);
      }

      return res(response);
    } catch (error) {
      CEPS[cep] = errorReturn;

      return res(errorReturn);
    }
  });
};
