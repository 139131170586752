import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaRegFileAlt } from "react-icons/fa";

export const ProfileDocumentationHeader = ({ message, data, status }) => {
  return (
    <Row>
      <Col className="d-flex justify-content-start align-items-center flex-row my-4">
        <FaRegFileAlt size={38} />
        <h1 className="ms-2">Envio de documentação</h1>
      </Col>
    </Row>
  );
};

export default ProfileDocumentationHeader;
