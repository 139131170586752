import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FormField } from "components/Form/FormField";
import { getCep } from "utils/hooks/useCep";

const BR = "br";

export const AddressForm = ({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  setFieldError,
  setFieldValue,
  setValues,
  data,
}) => {
  const { countries } = data;
  const handleCepBlur = React.useCallback(
    async e => {
      const value = e.target.value;

      if (value.length >= 8) {
        const { error, data: cepData } = await getCep(e.target.value);

        if (error) {
          setFieldError(
            "address_zipcode",
            "CEP inválido. Não encontrado nos correios.",
          );
        } else {
          setValues({
            ...values,
            address_city: cepData.localidade,
            address_neighborhood: cepData.bairro,
            address_state: cepData.uf,
            address: cepData.logradouro,
          });
        }
      } else {
        setFieldValue("address_zipcode", e.target.value);
      }
    },
    [setFieldValue, setValues, setFieldError, values],
  );

  const countryOptions = React.useMemo(
    () =>
      countries?.map(i => (
        <option key={i.Code} value={i.Code}>
          {i.Name}
        </option>
      )),
    [countries],
  );

  const isBrazilian = values.address_country.toString().toLowerCase() === BR;

  const defaultParams = {
    handleBlur,
    handleChange,
    hideLabel: false,
  };

  return (
    <div>
      <h4>Endereço para Envio:</h4>

      <FloatingLabel controlId="address_country" label="Nacionalidade">
        <Form.Select
          value={values.address_country}
          aria-label="Nacionalidade"
          className="mb-3"
          onChange={handleChange}
        >
          {countryOptions}
        </Form.Select>
      </FloatingLabel>

      <Container className="p-0">
        <Row>
          <Col className="d-flex flex-row align-items-center">
            <FormField
              label={isBrazilian ? "CEP" : "Código postal"}
              name="address_zipcode"
              placeholder={isBrazilian ? "00000-000" : ""}
              error={false}
              touched={touched.address_zipcode}
              value={values.address_zipcode}
              {...defaultParams}
              handleBlur={isBrazilian ? handleCepBlur : handleChange}
            />

            <FormField
              className="mb-3 ms-2"
              label="Número"
              name="address_number"
              error={errors.address_number}
              touched={touched.address_number}
              value={values.address_number}
              {...defaultParams}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              className="mb-3"
              label="Endereço completo"
              name="address"
              error={errors.address}
              touched={touched.address}
              value={values.address}
              {...defaultParams}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-row align-items-center">
            <FormField
              className="mb-3"
              hideLabel={false}
              label="Bairro"
              name="address_neighborhood"
              error={errors.address_neighborhood}
              touched={touched.address_neighborhood}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.address_neighborhood}
            />
            <FormField
              className="mb-3 ms-2"
              label="Cidade"
              name="address_city"
              error={errors.address_city}
              touched={touched.address_city}
              value={values.address_city}
              {...defaultParams}
            />
            <FormField
              className="mb-3 ms-2"
              label="Estado/Província/Região"
              name="address_state"
              error={errors.address_state}
              touched={touched.address_state}
              value={values.address_state}
              {...defaultParams}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              className="mb-3"
              label="Complemento"
              name="address_complement"
              error={errors.address_complement}
              touched={touched.address_complement}
              value={values.address_complement}
              {...defaultParams}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddressForm;
