import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LayoutDefault from "layouts/LayoutDefault";
import { ProfileDocumentationVerification } from "components/ProfileEdit/ProfileDocumentationVerification";
import { ProfileDocumentationHeader } from "components/ProfileEdit/ProfileDocumentationHeader";

export const ProfileDocumentationWithMessage = ({ message, data, status }) => {
  return (
    <LayoutDefault className="default-layout">
      <main>
        <Container>
          <ProfileDocumentationHeader />
          <Row>
            <Col md={4}>
              <ProfileDocumentationVerification />
            </Col>
            <Col className="text-center mt-5" md={8}>
              <h2>{message}</h2>
            </Col>
          </Row>
        </Container>
      </main>
    </LayoutDefault>
  );
};

export default ProfileDocumentationWithMessage;
