import React from "react";
import { Navigate } from "react-router-dom";
import { isLogged } from "utils/auth";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  if (!isLogged()) {
    return <Navigate replace to="/login" />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
